
export type Routes = Record<string, Record<string, string>>;

export const routes: Routes = {
    'homes-results': {
        'es': '/es/casas',
        'en': '/en/homes',
        'de': '/de/hauser'
    },
    'blog': {
        'es': '/es/blog',
        'en': '/en/blog',
        'de': '/de/blog'
    },
    'about-us': {
        'es': '/es/sobre-nosotros',
        'en': '/en/about-us',
        'de': '/de/uber-uns',
    },
    'homeowners': {
        'es': '/es/propietarios',
        'en': '/en/homeowners',
        'de': '/de/hausbesitzer',
    },
    'contact': {
        'es': '/es/contacto',
        'en': '/en/contact',
        'de': '/de/kontakt',
    },
    'privacy-policy': {
        'es': '/es/politica-privacidad',
        'en': '/en/privacy-policy',
        'de': '/de/datenschutz-bestimmungen',
    },
    'faq': {
        'es': '/es/faq',
        'en': '/en/faq',
        'de': '/de/faq',
    }
}