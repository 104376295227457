import { FC } from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  width: auto;
  height: 2.2em;
`;

export const Logo: FC = () => {
  return (
    <picture>
      <source srcSet="/images/logo_header.webp" type="image/webp" />
      <StyledImage
        alt="Feeling Home"
        src="/images/logo_header.png"
        width="291"
        height="46"
      />
    </picture>
  );
};
