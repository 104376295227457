import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Header } from "../organisms/header/header";
import Footer from "../organisms/footer";
import {
  CookieConsentBanner,
  useCookieConsent,
} from "@porscheofficial/cookie-consent-banner-react";
import Head from "next/head";
import Link from "next/link";
import Script from "next/script";

export type LayoutTemplateProps = PropsWithChildren<{
  page: string;
}>;

export const LayoutTemplate: FC<LayoutTemplateProps> = ({ page, children }) => {
  const acceptedCategories = useCookieConsent();

  return (
    <StyledContainer>
      {(Boolean(acceptedCategories.includes("analytics")) ||
        Boolean(acceptedCategories.includes("marketing"))) && (
        <>
          <Script
            id="gtm"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=AIzaSyBg0xk8reNEDMogrjpF9COaq2kGRAecHBI`}
          />
          <Script
            id="gtm_dl"
            dangerouslySetInnerHTML={{
              __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AIzaSyBg0xk8reNEDMogrjpF9COaq2kGRAecHBI', {
        page_path: window.location.pathname,
      });
    `,
            }}
          />
        </>
      )}
      <Header active={page} />
      <Content>{children}</Content>
      <Footer />
      <CookieConsentBanner
        btnLabelAcceptAndContinue="Agree and continue"
        btnLabelSelectAllAndContinue="Select all and continue"
        btnLabelOnlyEssentialAndContinue="Continue with technically required cookies only"
        btnLabelPersistSelectionAndContinue="Save selection and continue"
        contentSettingsDescription="You can decide which cookies are used by selecting the respective options below. Please note that your selection may impair in the functionality of the service."
        availableCategories={[
          {
            description:
              "Enable you to navigate and use the basic functions and to store preferences.",
            key: "technically_required",
            label: "Technically necessary cookies",
            isMandatory: true,
          },
          {
            description:
              "Enable us to determine how visitors interact with our service in order to improve the user experience.",
            key: "analytics",
            label: "Analysis cookies",
          },
          {
            description:
              "Enable us to offer and evaluate relevant content and interest-based advertising.",
            key: "marketing",
            label: "Marketing cookies",
          },
        ]}
      >
        We use cookies and similar technologies to provide certain features,
        enhance the user experience and deliver content that is relevant to your
        interests. Depending on their purpose, analysis and marketing cookies
        may be used in addition to technically necessary cookies. By clicking on
        &quot;Agree and continue&quot;, you declare your consent to the use of
        the aforementioned cookies.{" "}
        <button
          onClick={() =>
            document.dispatchEvent(new Event("cookie_consent_details_show"))
          }
          onKeyPress={() =>
            document.dispatchEvent(new Event("cookie_consent_details_show"))
          }
          type="button"
        >
          Here
        </button>{" "}
        you can make detailed settings or revoke your consent (in part if
        necessary) with effect for the future. For further information, please
        refer to our
        <Link href="/privacy-policy">Privacy Policy</Link>.
      </CookieConsentBanner>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Content = styled.div`
  flex: 1;
`;
