import { useState, useMemo, useEffect } from "react";

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>({
        width: typeof window !== 'undefined' ? window.innerWidth : undefined,
        height: typeof window !== 'undefined' ? window.innerHeight : undefined,
    });

    const output = useMemo(() => {
        return {
            ...windowSize,
        }
    }, [windowSize])

    useEffect(() => {
        if (typeof window === 'undefined') return;
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return output;
}
