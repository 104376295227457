import Button from "@atoms/button";
import { Icon, IconNames } from "@atoms/icon";
import React, { FC } from "react";
import styled from "styled-components";

export type IconButtonProps = {
  href?: string;
  onClick?: React.MouseEventHandler;
  icon: IconNames;
  title: string;
  className?: string;
};

export const IconButton: FC<IconButtonProps> = ({
  title,
  href,
  icon,
  onClick,
  className,
}) => {
  return (
    <StyledButton
      className={className}
      as="a"
      href={href}
      title={title}
      onClick={onClick}
    >
      <Icon name={icon} />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  display: inline-flex;
  background-color: #1d1d1d;
  border: 0.1875em solid #1d1d1d;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1.2em;
  text-decoration: none;
  transition: border-radius 0.3s;
  cursor: pointer;

  .fh-icon {
    color: #fff;
    font-size: 1.6em;
  }

  &:hover {
    border-radius: 40%;
  }
`;
