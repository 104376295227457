import clsx from "clsx";
import Link from "next/link";
import React, { FC, useEffect } from "react";
import { Trans } from "next-i18next";
import styled from "styled-components";
import { Icon, IconNames } from "../../atoms/icon/icon";

export type NavbarItemProps = {
  active: boolean;
  href: string;
  icon: IconNames;
  label: string;
  external?: boolean;
};

export const NavbarItem: FC<NavbarItemProps> = ({
  icon,
  href,
  active,
  label,
  external,
}) => {
  return (
    <Link href={href} passHref locale={false} legacyBehavior>
      <StyledAnchor
        className={clsx(active && "active")}
        target={external === true ? "_blank" : "_self"}
        rel={external === true ? "external" : "alternate"}
      >
        <Icon name={icon} />
        <span className="menu-label">
          <Trans>{label}</Trans>
        </span>
      </StyledAnchor>
    </Link>
  );
};

const StyledAnchor = styled.a`
  min-width: 4rem;
  font-family: "Work Sans", sans-serif;
  display: flex;
  align-items: center;
  color: #000000;
  background-color: #fff;
  border-bottom: 0.0625em solid #eff2f5;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0.5rem;
  font-size: 1.25rem;
  font-weight: 300;
  white-space: nowrap;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;

  .fh-icon {
    font-size: 1.5em;
    margin-right: 0.5em;
  }

  &.active {
    color: #fdbb30;
    border-bottom: 0.2em solid #fdbb30;
  }

  &:not(.active):hover {
    background-color: #fdbb30;
    color: #fff;
  }

  @media (min-width: 62em) {
    position: relative;
    overflow: hidden;
    font-weight: 400;
    border: none;
    height: 100%;
    justify-content: center;
    font-size: 1.02em;
    .fh-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      color: #fff;
      opacity: 0;
      top: -3.2em;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      transition: opacity 0.3s, top 0.3s;
      font-size: 2em;
    }

    .menu-label {
      opacity: 1;
      text-align: center;
      transition: opacity 0.3s;
    }

    &.active {
      .menu-label {
        margin-top: 0.3em;
      }
    }

    &:not(.active):hover {
      .fh-icon {
        top: 0;
        opacity: 1;
      }
      .menu-label {
        opacity: 0;
      }
    }
  }
`;
