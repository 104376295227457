import React, { FC } from "react";
import styled, { keyframes } from "styled-components";

export type CircleLoaderProps = {
  size?: string;
  borderSize?: string;
  speed?: string;
};

export type DotsLoaderProps = {
  color?: "default" | "secondary";
};

export type LoaderProps = { className?: string } & (
  | ({ variant?: "circle" } & CircleLoaderProps)
  | ({ variant: "dots" } & DotsLoaderProps)
);

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const dots1Animation = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const dots2Animation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const dots3Animation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const CircleLoader = styled.span<CircleLoaderProps>`
  display: block;
  border: ${({ borderSize }) => borderSize ?? "0.2em"} solid #f3f3f3;
  border-top: ${({ borderSize }) => borderSize ?? "0.2em"} solid #fdbb30;
  border-radius: 50%;
  width: ${({ size }) => size ?? "1em"};
  height: ${({ size }) => size ?? "1em"};
  animation: ${rotateAnimation} ${({ speed }) => speed ?? "1s"} linear infinite;
`;

const colors = {
  default: "#fdbb30",
  secondary: "#fff",
};

const DotsLoader = styled.span<DotsLoaderProps>`
  display: inline-block;
  position: relative;
  height: 14px;
  width: 80px;

  div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ color }) => colors[color ?? "default"]};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: ${dots1Animation} 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: ${dots2Animation} 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: ${dots2Animation} 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: ${dots3Animation} 0.6s infinite;
    }
  }
`;

const variants: Record<string, React.ElementType> = {
  circle: function CircleComponent(props: CircleLoaderProps) {
    return <CircleLoader {...props} />;
  },
  dots: function DotsComponent(props: DotsLoaderProps) {
    return (
      <DotsLoader {...props}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </DotsLoader>
    );
  },
};

export const Loader: FC<LoaderProps> = ({ variant, ...rest }) => {
  const Component = variants[variant ?? "circle"];
  return <Component {...rest} />;
};
