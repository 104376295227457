import { Loader } from "@atoms/loader/loader";
import React, { FC, forwardRef } from "react";
import styled, { css } from "styled-components";

export interface ButtonProps {
  variant?: "primary" | "secondary" | "action" | "transparent";
  disabled?: boolean;
  type?: "button" | "submit";
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  showLoading?: boolean;
  as?: string;
  children?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ as, showLoading, children, ...rest }, ref) => {
    return (
      <StyledButton
        {...rest}
        showLoading={showLoading}
        as={as as any}
        ref={ref}
      >
        <div className="loader-container">
          <Loader className="loader" variant="dots" color="secondary" />
        </div>
        <span className="container">{children}</span>
      </StyledButton>
    );
  }
);
Button.displayName = "Button";

const variants = {
  primary: css`
    color: #000000;
    // text-shadow: 0 0.125em 0 #304e71;
    background-color: #fdbb30;
    border: 0.0625em solid #fdbb30;
    &:not([disabled]):focus,
    &:not([disabled]):hover {
      // text-shadow: 0 -0.0625em 0 #304e71;
    }
  `,
  secondary: css`
    color: #000000;
    background-color: #fdbb30;
    border: 0.0625em solid #fdbb30;
    &:not([disabled]):focus,
    &:not([disabled]):hover {
      color: #000000;
      background-color: #fdbb30;
      border: 0.0625em solid #fdbb30;
    }
  `,
  action: css`
    color: #000000;
    text-shadow: 0 0.125em 0 #fdbb30;
    background-color: #fdbb30;
    border: 0.0625em solid #fdbb30;
    &:not([disabled]):focus,
    &:not([disabled]):hover {
      text-shadow: 0 -0.0625em 0 #fdbb30;
    }
  `,
  transparent: css`
    color: #fff;
    border: 0.0625em solid #fff;
    text-transform: uppercase;
    background-color: transparent;
  `,
};

const StyledButton = styled.button<ButtonProps>`
  font-family: "Work Sans", sans-serif;
  transition: all 0.3s ease-out;
  border-radius: 2;
  padding: 0.65em 1em;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  min-width: 5rem;

  ${({ variant }) => variant && variants[variant]}

  &:is([disabled]) {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:not([disabled]):focus,
  &:not([disabled]):hover {
    box-shadow: 0 1.25em 0 rgb(255 255 255 / 20%) inset;
  }

  .container {
    opacity: 1;
    transition: opacity 0.3s;
  }

  .loader-container {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
  }

  ${({ showLoading }) =>
    showLoading &&
    css`
      .container {
        opacity: 0;
      }
      .loader-container {
        opacity: 1;
      }
    `}
`;
