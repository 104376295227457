import { AnimatePresence, motion } from "framer-motion";
import { Icon, IconNames } from "@atoms/icon";
import { Logo } from "@atoms/logo";
import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { useState } from "react";
import { useCallback } from "react";
import { useWindowSize } from "@hooks/window-size";
import { useEffect } from "react";
import NavbarItem from "@molecules/navbar-item";
import LanguageSelector from "@molecules/language-selector";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { routes } from "@fhlib/routes";

const HeaderLinks: FC<{ active?: string }> = ({ active }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const links = useMemo<
    {
      id: string;
      path: string;
      icon: IconNames;
      label: string;
      external?: boolean;
    }[]
  >(
    () => [
      {
        id: "reservations",
        icon: "home",
        path: routes["homes-results"][router?.locale ?? "en"],
        label: t("menu-homes"),
      },
      {
        id: "blog",
        icon: "blog",
        path: routes["blog"][router?.locale ?? "en"],
        label: t("menu-blog"),
        external: false,
      },
      {
        id: "faq",
        icon: "question",
        path: routes["faq"][router?.locale ?? "en"],
        label: t("menu-faq"),
      },
      {
        id: "about-us",
        icon: "info",
        path: routes["about-us"][router?.locale ?? "en"],
        label: t("menu-about-us"),
      },
      {
        id: "homeowners",
        icon: "download",
        path: routes["homeowners"][router?.locale ?? "en"],
        label: t("menu-homeowners"),
      },
      {
        id: "contact",
        icon: "evelope",
        path: routes["contact"][router?.locale ?? "en"],
        label: t("menu-contact"),
      },
      {
        id: "rentacar",
        icon: "car",
        path: "https://rentacarvanrell.com/",
        label: t("menu-rentacar"),
        external: true,
      },
      {
        id: "transfer",
        icon: "transfer",
        path: 'https://brumtrips.com/87f8a515-0971-48f8-93b2-7054b37819cc',
        label: t("menu-transfer"),
        external: true,
      },
    ],
    [t, router]
  );

  return (
    <div className="navbar-links">
      {links.map((c) => (
        <NavbarItem
          key={c.id}
          active={c.id === active}
          href={c.path}
          icon={c.icon}
          label={c.label}
          external={c.external === true}
        />
      ))}
    </div>
  );
};

export type HeaderProps = {
  active?: string;
};

export const Header: FC<HeaderProps> = ({ active }) => {
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(true);
  const [mobileVersion, setMobileVersion] = useState<boolean | null>(null);
  const handleToggle = useCallback(() => setCollapsed((c) => !c), []);

  useEffect(
    () => setMobileVersion(width === undefined || width < 992),
    [width]
  );

  return (
    <HeaderContainer>
      <div className="header-content">
        <div className="navbar-header">
          <div className="logo-container">
            <Link href="/" title="Home">
              <Logo />
            </Link>
          </div>
          <button className="btn-expander" onClick={handleToggle}>
            <Icon name="menu" />
          </button>
        </div>

        {mobileVersion !== null &&
          (mobileVersion ? (
            <AnimatePresence initial={false}>
              {!collapsed && (
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  className="collapsable-animation"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  <HeaderLinks active={active} />
                  <LanguageSelector />
                </motion.section>
              )}
            </AnimatePresence>
          ) : (
            <>
              <HeaderLinks active={active} />
              <LanguageSelector />
            </>
          ))}
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  background-color: #fff;
  box-shadow: 0 0.0625em 0.3125em rgb(0 0 0 / 20%);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;

  .collapsable-animation {
    overflow: hidden;
  }

  .header-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .navbar-header {
    padding: 0.5em 0.5em 0.5em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5em;
    align-self: center;
    width: 100%;

    .logo-container {
      flex: 1;
      display: flex;
      align-items: center;

      img {
        display: block;
      }
    }

    .btn-expander {
      border: 0;
      background-color: transparent;
      color: #000000;
      font-size: 2.4em;
      margin: 0;
      padding: 0;
      transition: color 0.3s;
      cursor: pointer;
      &:hover {
        color: #fdbb30;
      }
    }
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top: 0.0625em solid #eff2f5;
  }

  @media (min-width: 48em) {
    .navbar-header {
      padding: 0.5em 1em;
    }
  }

  @media (min-width: 62em) {
    .header-content {
      flex-direction: row;
    }

    .navbar-header {
      width: auto;

      .logo-container {
        flex: 0;
        padding: 0 1em 0 1em;
      }
      .btn-expander {
        display: none;
      }
    }

    .navbar-links {
      border-top: 0;
      flex-direction: row;
      flex: 1;
      justify-content: flex-end;
    }
  }

  @media (min-width: 75em) {
    .navbar-header {
      padding: 0.5em 0;
    }
    .header-content {
      max-width: 75em;
    }
  }
`;
