import React, { FC, useMemo } from "react";
import styled, { css, Interpolation } from "styled-components";
import { TypographyProps, TypographyVariants } from "./types";

type TypographyVariantSetting = {
  styles: Interpolation<any>;
  component: string | React.ElementType;
};

const variantStyles: Record<TypographyVariants, TypographyVariantSetting> = {
  body1: {
    styles: css`
      margin: 0;
    `,
    component: "p",
  },
  body2: {
    styles: css`
      font-size: 0.8125rem;
      margin: 0;
    `,
    component: "p",
  },
  body3: {
    styles: css`
      font-size: 0.75rem;
      margin: 0;
    `,
    component: "p",
  },
  title1: {
    component: "h1",
    styles: css`
      font-family: "DomaineDispNar", sans-serif;
      font-size: 2.5rem;
      text-transform: capitalize;
      line-height: normal;
      font-weight: 500;
      margin-bottom: 1rem;
    `,
  },
  title2: {
    component: "h2",
    styles: css`
      font-size: 1.4rem;
      text-transform: uppercase;
      line-height: normal;
      font-weight: 400;
      margin-bottom: 0.8rem;
    `,
  },
  title3: {
    component: "h3",
    styles: css`
      font-size: 1rem;
      text-transform: uppercase;
      line-height: normal;
      font-weight: 400;
      margin-bottom: 0.8rem;
    `,
  },
  small: {
    component: "small",
    styles: css`
      font-size: 0.8125rem;
      margin: 0;
    `,
  },
};

const colors = {
  default: "#1d1d1d",
  error: "#e43140",
};

const StyledTypography = styled.span<TypographyProps>`
  font-family: "Work Sans", sans-serif;
  color: ${({ color }) => colors[color ?? "default"]};
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  padding: 0;
  font-weight: ${({ bold }) => (bold ? "bold" : 300)};
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  ${({ variant }) => variantStyles[variant ?? "body1"]?.styles}
  ${({ paragraph }) =>
    paragraph &&
    css`
      margin-bottom: 1rem;
    `}
`;

export const Typography: FC<TypographyProps> = ({ as, ...rest }) => {
  const forwardAs = useMemo(() => {
    let asComputed = as;
    if (!as && rest.bold) {
      asComputed = "b";
    }
    return asComputed ?? variantStyles[rest.variant ?? "body1"]?.component;
  }, [as, rest.bold, rest.variant]);
  return <StyledTypography as={forwardAs as any} {...rest} />;
};
