import React, { FC, useMemo } from "react";
import styled from "styled-components";

const AvailableIcons = {
  home: "&#xe600;",
  close: "&#xe601;",
  check: "&#xe602;",
  blog: "&#xe603;",
  question: "&#xe607;",
  info: "&#xe606;",
  evelope: "&#xe60b;",
  download: "&#xe60d;",
  calendar: "&#xe639;",
  facebook: "&#xe60e;",
  twitter: "&#xe60f;",
  instagram: "&#xe634;",
  menu: "&#xe63d;",
  "arrow-down": "&#xe63a;",
  user: "&#xe637;",
  "at-sign": "&#xe638;",
  "home-2": "&#xe618;",
  people: "&#xe633;",
  door: "&#xe629;",
  bed: "&#xe61d;",
  "satellite-tv": "&#xe620;",
  parking: "&#xe616;",
  bathtub: "&#xe619;",
  "basic-amenities": "&#xe61c;",
  hairdryer: "&#xe621;",
  "heating-system": "&#xe622;",
  "fire-extinguisher": "&#xe623;",
  "swimming-pool": "&#xe627;",
  kitchen: "&#xe628;",
  dryer: "&#xe62a;",
  "washing-machine": "&#xe62b;",
  tv: "&#xe62c;",
  "air-conditioner": "&#xe62d;",
  wifi: "&#xe62f;",
  "bell-intercom": "&#xe630;",
  internet: "&#xe632;",
  mobile: "&#xe63b",
  car: "&#xe63e",
  transfer: "&#xe63f",
};

export type IconNames = keyof typeof AvailableIcons;

const IconContainer = styled.span`
  font-family: "feelinghome", Fallback, Arial;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export type IconProps = {
  name: IconNames;
  size?: string;
};

export const Icon: FC<IconProps> = ({ name }) => {
  const char = useMemo(
    () =>
      name in AvailableIcons ? AvailableIcons[name] : AvailableIcons.question,
    [name]
  );

  return (
    <IconContainer
      className="fh-icon"
      dangerouslySetInnerHTML={{ __html: char }}
    ></IconContainer>
  );
};
