import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { IconButton } from "@atoms/icon-button";
import { Typography } from "@atoms/typography";
import { useTranslation } from "next-i18next";
import Link from "next/link";

export const Footer: FC = () => {
  const { t } = useTranslation("common");
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <StyledContainer>
      <StyledCopyrightContainer>
        <StyledTypography variant="body2">
          <span>Feeling Home © {year}</span>
          <span>|</span>
          <Link href="/privacy-policy" passHref legacyBehavior>
            <StyledAnchor>{t("privacy-policy")}</StyledAnchor>
          </Link>
        </StyledTypography>
      </StyledCopyrightContainer>
      <StyledIconsContainer>
        <IconButton
          href="https://www.facebook.com/feelinghomeSL/"
          icon="facebook"
          title="Feeling Home Facebook"
        />
        <IconButton
          href="https://www.facebook.com/feelinghomeSL/"
          icon="twitter"
          title="Feeling Home Twiiter"
        />
        <IconButton
          href="https://www.facebook.com/feelinghomeSL/"
          icon="instagram"
          title="Feeling Home Instagram"
        />
      </StyledIconsContainer>
      <StyledLogosContainer>
        <img
          alt="Financiado por la Unión Europea"
          src="/images/ES_FundedbytheEU.png"
          height={60}
          loading="lazy"
        />
        <img
          alt="Llamar"
          src="/images/Logo_PRTR.png"
          height={160}
          loading="lazy"
        />
      </StyledLogosContainer>
    </StyledContainer>
  );
};

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledContainer = styled.footer`
  display: flex;
  background-color: #f0f0f0;
  border-top: 0.3125em solid #f0f0f0;
  padding: 0.5em 2em 1em 2em;
  margin-top: 4em;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
  flex-wrap: wrap;

  @media (min-width: 48em) {
    flex-direction: row;
  }
`;

const StyledCopyrightContainer = styled.div`
  flex: 1;
  text-align: center;
  @media (min-width: 48em) {
    text-align: left;
  }
`;

const StyledIconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.625em;
`;

const StyledTypography = styled(Typography)`
  color: #1d1d1d;
  display: flex;
  gap: 0.5em;
`;

const StyledLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60px;
  @media (min-width: 48em) {
    padding-top: 0px;
    flex-direction: row;
    gap: 20px;
  }
`;
